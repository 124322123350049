define('ember-font-awesome/components/fa-icon', ['exports', 'ember-font-awesome/utils/try-match'], function (exports, _tryMatch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      deprecate = Ember.deprecate,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      isArray = Ember.isArray;


  var FaIconComponent = Ember.Component.extend({
    tagName: 'i',

    classNames: ['fa'],

    classNameBindings: ['iconCssClass', 'flipCssClass', 'rotateCssClass', 'sizeCssClass', 'pullCssClass', 'stackCssClass', 'spin:fa-spin', 'fixedWidth:fa-fw', 'listItem:fa-li', 'border:fa-border', 'pulse:fa-pulse', 'inverse:fa-inverse'],

    attributeBindings: ['ariaLabel:aria-label', 'ariaHiddenAttribute:aria-hidden', 'title', 'style'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.checkDeprecations();
    },


    style: computed('color', function () {
      var color = get(this, 'color');
      if (!color) {
        return;
      }
      return Ember.String.htmlSafe('color:' + color);
    }),

    iconCssClass: computed('icon', 'params.[]', function () {
      var icon = get(this, 'icon');
      var params = get(this, 'params');

      icon = icon || isArray(params) && params[0];

      if (icon) {
        return (0, _tryMatch.default)(icon, /^fa-/) ? icon : 'fa-' + icon;
      }
    }),

    flipCssClass: computed('flip', function () {
      var flip = get(this, 'flip');
      if (!flip) {
        return;
      }
      return (0, _tryMatch.default)(flip, /^fa-flip/) ? flip : 'fa-flip-' + flip;
    }),

    rotateCssClass: computed('rotate', function () {
      var rotate = get(this, 'rotate');
      if (!rotate) {
        return;
      }

      if ((0, _tryMatch.default)(rotate, /^fa-rotate/)) {
        return rotate;
      } else {
        return 'fa-rotate-' + rotate;
      }
    }),

    sizeCssClass: computed('size', function () {
      var size = get(this, 'size');
      if (!size) {
        return;
      }

      if ((0, _tryMatch.default)(size, /^fa-/)) {
        return size;
      } else if ((0, _tryMatch.default)(size, /(?:lg|x)$/)) {
        return 'fa-' + size;
      } else {
        return 'fa-' + size + 'x';
      }
    }),

    pullCssClass: computed('pull', function () {
      var pull = get(this, 'pull');
      if (!pull) {
        return;
      }
      return 'fa-pull-' + pull;
    }),

    stackCssClass: computed('stack', function () {
      var stack = get(this, 'stack');
      if (!stack) {
        return;
      }

      if ((0, _tryMatch.default)(stack, /^fa-/)) {
        return stack;
      } else if ((0, _tryMatch.default)(stack, /x$/)) {
        return 'fa-stack-' + stack;
      } else {
        return 'fa-stack-' + stack + 'x';
      }
    }),

    ariaHiddenAttribute: computed('ariaHidden', function () {
      var ariaHidden = get(this, 'ariaHidden');
      return ariaHidden !== false ? 'true' : undefined;
    }),

    checkDeprecations: function checkDeprecations() {
      var icon = get(this, 'icon');
      var params = get(this, 'params');

      var iconOrParam = icon || isArray(params) && params[0];
      if (iconOrParam) {
        if (iconOrParam.startsWith('fa-')) {
          var preferedIcon = iconOrParam.substring(3);
          deprecate('Passing the icon prefixed with \'fa-\' (' + iconOrParam + ') is deprecated and will be removed in v4. Use \'' + preferedIcon + '\' instead.', false, { id: 'ember-font-awesome.no-fa-prefix', until: '4.0.0' });
        }
      }

      var size = getWithDefault(this, 'size', '').toString();
      if (size.endsWith('x')) {
        var preferedSize = size.substring(0, size.length - 1);
        deprecate('Passing \'size\' as \'' + size + '\' to fa-icon is deprecated and will be removed in v4. Use size=\'' + preferedSize + '\' instead', false, { id: 'ember-font-awesome.no-size-suffix', until: '4.0.0' });
      }
    }
  });

  FaIconComponent.reopenClass({
    positionalParams: 'params'
  });

  exports.default = FaIconComponent;
});