define('ember-aria-checkbox/components/aria-checkbox', ['exports', 'ember-aria-checkbox/templates/components/aria-checkbox'], function (exports, _ariaCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KEYCODES = {
    'RETURN': 13,
    'SPACE': 32
  };

  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    layout: _ariaCheckbox.default,
    classNameBindings: ['focus:focus'],
    attributeBindings: ['role', 'ariaChecked:aria-checked', 'tabindex'],
    role: 'checkbox',
    ariaChecked: computed('checked', function () {
      return get(this, 'checked') ? 'true' : 'false';
    }),
    focus: false,
    checked: false,
    tabindex: 0,
    keyDown: function keyDown(e) {
      var flag = false;
      switch (e.keyCode) {
        case KEYCODES.SPACE:
          this.toggleProperty('checked');
          flag = true;
          break;
        default:
          break;
      }

      if (flag) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    click: function click() {
      this.toggleProperty('checked');
    },
    focusIn: function focusIn() {
      set(this, 'focus', true);
    },
    focusOut: function focusOut() {
      set(this, 'focus', false);
    }
  });
});