define('ember-cli-notifications/components/notification-container', ['exports', 'ember-cli-notifications/templates/components/notification-container', 'ember-cli-notifications/styles/components/notification-container'], function (exports, _notificationContainer, _notificationContainer2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    position: 'top',
    layout: _notificationContainer.default,
    styles: _notificationContainer2.default,

    classNameBindings: ['computedPosition'],
    attributeBindings: ['computedStyle:style', 'position:data-test-notification-container'],

    zindex: '1060',

    computedPosition: computed('position', function () {
      return this.get('styles.c-notification__container--' + this.get('position'));
    }),

    computedStyle: computed('zindex', function () {
      return htmlSafe('z-index: ' + this.get('zindex') + ';');
    })
  });
});