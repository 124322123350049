define('ember-cli-bootstrap-datetimepicker/components/bs-datetimepicker', ['exports', 'ember-cli-bootstrap-datetimepicker/templates/components/bs-datetimepicker'], function (exports, _bsDatetimepicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var defaults = $.fn.datetimepicker.defaults;
  exports.default = Component.extend({
    layout: _bsDatetimepicker.default,
    tagName: 'div',
    classNames: ['input-group date'],
    placeholder: '',
    openOnFocus: false,
    isMobile: /Android|iPhone|iPod|Windows Phone/i.test(navigator.userAgent),
    showIcon: true,

    iconClasses: computed('isTime', function () {
      if (this.get('isTime')) {
        return this.getWithDefault('config.icons.time', defaults.icons.time);
      }

      return this.getWithDefault('config.icons.date', defaults.icons.date);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var icons = {
        clear: this.getWithDefault('config.icons.clear', defaults.icons.clear),
        close: this.getWithDefault('config.icons.close', defaults.icons.close),
        date: this.getWithDefault('config.icons.date', defaults.icons.date),
        down: this.getWithDefault('config.icons.down', defaults.icons.down),
        next: this.getWithDefault('config.icons.next', defaults.icons.next),
        previous: this.getWithDefault('config.icons.previous', defaults.icons.previous),
        time: this.getWithDefault('config.icons.time', defaults.icons.time),
        today: this.getWithDefault('config.icons.today', defaults.icons.today),
        up: this.getWithDefault('config.icons.up', defaults.icons.up)
      };

      this.$().datetimepicker({
        date: this.getWithDefault('date', null),
        daysOfWeekDisabled: this.getWithDefault('daysOfWeekDisabled', defaults.daysOfWeekDisabled),
        disabledDates: this.getWithDefault('disabledDates', defaults.disabledDates),
        disabledHours: this.getWithDefault('disabledHours', defaults.disabledHours),
        enabledDates: this.getWithDefault('enabledDates', defaults.enabledDates),
        enabledHours: this.getWithDefault('enabledHours', defaults.enabledHours),
        focusOnShow: this.getWithDefault('focusOnShow', defaults.focusOnShow),
        format: this.getWithDefault('format', defaults.format),
        icons: icons,
        ignoreReadonly: this.isMobile || defaults.ignoreReadonly,
        inline: this.getWithDefault('inline', defaults.inline),
        locale: this.getWithDefault('locale', defaults.locale),
        maxDate: this.getWithDefault('maxDate', defaults.maxDate),
        minDate: this.getWithDefault('minDate', defaults.minDate),
        showClear: this.getWithDefault('showClear', defaults.showClear),
        showClose: this.getWithDefault('showClose', defaults.showClose),
        showTodayButton: this.getWithDefault('showTodayButton', defaults.showTodayButton),
        sideBySide: this.getWithDefault('sideBySide', defaults.sideBySide),
        timeZone: this.getWithDefault('timeZone', defaults.timeZone),
        useCurrent: this.getWithDefault('useCurrent', false),
        viewDate: this.getWithDefault('viewDate', defaults.viewDate),
        viewMode: this.getWithDefault('viewMode', defaults.viewMode),
        widgetPositioning: this.getWithDefault('widgetPositioning', defaults.widgetPositioning)
      }).on('dp.change', function (e) {
        // Convert moment to js date or default to null
        var newDate = e.date && e.date.toDate() || null;

        _this.set('date', newDate);
        _this.sendAction('change', newDate);
      });

      this.addObserver('date', function () {
        this.$().data('DateTimePicker').date(this.getWithDefault('date', null));
      });

      this.addObserver('maxDate', function () {
        this.$().data('DateTimePicker').maxDate(this.get('maxDate'));
      });

      this.addObserver('minDate', function () {
        this.$().data('DateTimePicker').minDate(this.get('minDate'));
      });

      this.addObserver('locale', function () {
        this.$().data('DateTimePicker').locale(this.get('locale'));
      });

      this.addObserver('format', function () {
        this.$().data('DateTimePicker').format(this.get('format'));
      });

      this.addObserver('viewMode', function () {
        this.$().data('DateTimePicker').viewMode(this.get('viewMode'));
      });

      this.addObserver('timeZone', function () {
        this.$().data('DateTimePicker').timeZone(this.get('timeZone'));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeObserver('date');
      this.removeObserver('maxDate');
      this.removeObserver('minDate');
      this.removeObserver('locale');
      this.removeObserver('format');
      this.removeObserver('viewMode');
      this.removeObserver('timeZone');

      // Running the `ember` application embedded might cause the DOM to be cleaned before
      var dateTimePicker = this.$().data('DateTimePicker');
      if (dateTimePicker) {
        dateTimePicker.destroy();
      }
    },


    actions: {
      focus: function focus() {
        if (this.get('openOnFocus')) {
          this.$().data('DateTimePicker').show();
        }
      }
    }
  });
});