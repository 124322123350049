// source : https://github.com/bbooth/ember-cli-sweet-alert/blob/master/vendor/shim.js

(function() {
	
	/* globals define, swal */
	['swal', 'sweetalert'].forEach(function(name) {
		define(name, [], function() {
			'use strict';

			return {
				default: swal
			};
		});
	});

})();